import { motion } from "framer-motion"
import PropTypes from "prop-types"
import React from "react"
import styled, { css } from "styled-components"
import theme from "styled-theming"
import { colors, zIndex } from "../../styles/theme"
import MenuItem from "./MenuItem"

const sideDrawerThemed = theme("mode", {
  light: css`
    background: ${colors.grayscale.light5};
    & > ul ul {
      background-color: ${colors.secondary.light};
    }
  `,
  dark: css`
    background: ${colors.siteBackground.dark};
    & > ul ul {
      background-color: ${colors.grayscale.light1};
    }
  `,
})

const StyledSidedrawer = styled(motion.nav)`
  ${sideDrawerThemed};
  box-shadow: 0 1rem 2rem  ${colors.grayscale.light1};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  z-index: ${zIndex.floating};
  min-width: 0;
  /* width: 100%; */
  max-width: 40rem;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  > ul::--webkit-scrollbar {
    width:none;
  }
  > ul::--webkit-scrollbar-track {
    background-color: blue;
  }
  }
  > ul::--webkit-scrollbar-thumb {
    border: 0;
  }
  & > ul {
    width: 100%;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    overflow-y: scroll;
    margin: 0;
    margin-bottom: 4rem;
    height: 100%;
    line-height: 5rem;
    padding: 0;
    list-style: none;
    position: relative;
    display: flex;
    justify-content:center;
    flex-direction: column;
    li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &:first-child {
        padding-top: 1rem;
      }
      padding: 0 1rem;
    }
    a {
      display: inline-flex;
      font-size: 2rem;
    }
    button {
      margin-left: auto;
    }
    ul {
      flex-basis: 100%;
      text-align: left;
      margin-left:0;
      padding-left: 0;
      position: relative;
      width: 100%;
      height: auto;
      top: 0;
      margin-bottom:2rem;
      li {
        padding: 0;
        display:block;
        box-sizing: border-box-box;
        width:100%;
      }
      a {
        padding-left: 2rem;
      }
    }
  }

`

const SidedrawerNavigation = ({ items }) => {
  return (
    <StyledSidedrawer
      animate={{ width: `40rem` }}
      transition={{ duration: 0.251, type: "linear" }}
    >
      <ul>
        <li>
          <MenuItem
            menuItem={{
              url: "/",
              title: "Home",
              label: "Home",
              children: [],
              cssClasses: ["home"],
            }}
          />
        </li>
        {items &&
          items.map((menuItem) => (
            <li key={menuItem.id}>
              <MenuItem menuItem={menuItem} />
            </li>
          ))}
      </ul>
    </StyledSidedrawer>
  )
}

SidedrawerNavigation.propTypes = {
  items: PropTypes.array.isRequired,
}

export default SidedrawerNavigation
