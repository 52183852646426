import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import styled from "styled-components";
import theme from "styled-theming";
import useScrollPosition from "../hooks/useScrollPosition";
import { colors, device, zIndex } from "../styles/theme";
import { Container } from "./containers/Container";
import { MenuToggle, PrimaryMenu } from "./menus";
import UniversalLink from "./UniversalLink";
import HeaderPhone from "./HeaderPhone";
const backgroundColor = theme("mode", {
  light: colors.siteBackground.light,
  dark: colors.grayscale.light1,
})
const StyledHeader = styled.header`
  background-color: ${backgroundColor};
  position: relative;
  transition: box-shadow 200ms ease;
  padding: 1rem 0;
  width: 100%;
  top: 0;
  left: 0;
  @media ${device.md} {
    position: fixed;
    z-index: ${zIndex.floating};
    padding: 2rem 0;
  }
  &.header--add-shadow {
    box-shadow: 0 0.125rem 1rem hsla(0, 0%, 0%, 60%);
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media ${device.xl} {
    flex-wrap: nowrap;
  }
`

  const LogoContainer = styled.div`
    width: 30vw;
    @media screen and (max-width: 750px) {
     width: 70vw;
    }
    > a {
      text-decoration: none;
    }
  `

export default function Header() {
  const {
    wp: {
      globalSiteSettings: {
        globalSiteSettings: {
          headerLogo: {
            localFile: {
              childImageSharp: { gatsbyImageData },
            },
          },
        },
      },
    },
  } = useStaticQuery(graphql`{
  wp {
    globalSiteSettings {
      globalSiteSettings {
        headerLogo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 200, layout: FIXED, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
}
`)

  const [boxShadowClass, setBoxShadowClass] = useState("")

  useScrollPosition(
    ({ currPos }) => {
      const isScrolled = currPos.y
      isScrolled < 0
        ? setBoxShadowClass("header--add-shadow")
        : setBoxShadowClass("")
    },
    [boxShadowClass]
  )

  return (
    <StyledHeader className={boxShadowClass}>
      <StyledContainer>
        <LogoContainer>
          <UniversalLink title="Navigate to the home page" to="/">
            {<GatsbyImage image={gatsbyImageData} alt="logo" />}
          </UniversalLink>
        </LogoContainer>
        <div>
          {/* <DarkModeToggle /> */}
          <MenuToggle />
        </div>
        <PrimaryMenu />
      </StyledContainer>
    </StyledHeader>
  );
}
