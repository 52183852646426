import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import {
  FaFacebookF,
  FaMailBulk,
  FaPhoneSquare,
  FaRegMap
} from "react-icons/fa";
import styled, { css } from "styled-components";
import theme from "styled-theming";
import { colors, device } from "../styles/theme";
import { flatListToHierarchical } from "../utils/get-url-path";
import Button from "./Button";
import ContactWidget from "./ContactWidget";
import { Container } from "./containers/Container";
import Phone from "./Phone";
import UniversalLink from "./UniversalLink";

const themedFollowIcon = theme("mode", {
  light: css`
    border-color: ${colors.primary.default};
    color: ${colors.primary.default};
    &:hover {
      background-color: ${colors.primary.light};
      color: ${colors.offWhite};
    }
    &:hover,
    &:focus {
      border-color: ${colors.primary.light};
    }
  `,
  dark: css`
    color: ${colors.primary.lighter};
    &:hover {
      background-color: ${colors.primary.lightest};
    }
    &:hover,
    &:focus {
      border-color: ${colors.primary.lightest};
      color: ${colors.offWhite};
    }
  `,
})
const themedLink = theme("mode", {
  light: css`
    a {
      text-decoration: none;
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      color: ${colors.black};
      box-shadow: 0 0 0 hsla(0, 100%, 0%, 0%);
      &:hover,
      &:focus {
        background-color: ${colors.accentLight.default};
        box-shadow: 0 0.5rem 1rem hsla(0, 100%, 0%, 40%);
      }
      &:focus {
        outline: none;
      }
    }
  `,
  dark: css`
    a {
      text-decoration: none;
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      color: ${colors.white};
      &:hover,
      &:focus {
        background-color: ${colors.accentLight.dark};
        box-shadow: 0 0.5rem 1rem hsla(0, 100%, 0%, 40%);
      }
      &:focus {
        color: ${colors.black};
        outline: none;
      }
    }
  `,
})
const themedFooter = theme("mode", {
  light: css`
    background-color: ${colors.accentDark.default};
  `,
  dark: css`
    background-color: ${colors.siteBackground.dark};
    border-top: 2px solid ${colors.primary.default};
    .gatsby-image-wrapper {
      transition: filter 200ms ease;
      filter: invert();
    }
  `,
})
const StyledFooter = styled.footer`
  ${themedFooter};
  margin-top: 2rem;
  padding: 3rem 0 2rem;
`
const StyledContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  @media ${device.xl} {
    grid-template-columns: repeat(3, 1fr);
  }
  .gatsby-image-wrapper {
    max-width: 22.5rem;
  }
  blockquote {
    q {
      font-style: italic;
    }
    h6 {
      margin: 0;
      text-transform: uppercase;
      font-family: var(--font-stack-title);
      letter-spacing: 1px;
    }
  }
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: 1/-1;
    @media ${device.lg} {
      grid-column: 3 /-1;
    }
    h3 {
      svg {
        margin-right: 1rem;
      }
      text-transform: uppercase;
      font-style: italic;
      font-family: var(--font-stack-title);
    }
    ul {
      margin: 0;
      margin-top: 2rem;
      padding: 0;
      justify-self: center;
      align-self: center;
      li {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        &:not(:last-of-type) {
          ${themedLink};
        }
        &:last-child {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          & > :first-child {
            text-align: center;
            flex-basis: 100%;
            margin-bottom: 1rem;
          }
          a {
            ${themedFollowIcon};
            border-width: 2px;
            border-style: solid;
            padding: 1rem;
            border-radius: 50%;
            font-weight: 400;
            font-size: var(--default-font-size);
            height: 2rem;
            width: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 200ms ease;
            &:hover,
            &:focus {
              transform: translateY(-1rem);
              box-shadow: 0 0.5rem 1rem hsla(0, 100%, 0%, 40%);
            }
            svg {
              color: inherit;
            }
          }
        }
      }
    }
  }
`
const LegalNavContainer = styled.div`
  ${themedLink};
  grid-column: 1/-1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  > nav {
    flex: 1;
    display: flex;
    justify-content: center;
    @media ${device.md} {
      justify-content: flex-start;
    }
    a {
      &:not(:last-child) {
        margin-right: 2rem;
      }
      font-size: 1.4rem;
      text-decoration: none;
    }
  }
  > small {
    display: block;
    margin-top: 2rem;
    text-align: center;
    @media ${device.md} {
      width: 50%;
      text-align: right;
      margin-top: 0;
    }
  }
`

const FooterNav = styled.nav`
  ${themedLink};
  margin-bottom: 2rem;
  a {
    font-size: 1.6rem;
    display: block;
  }
  ul {
    list-style: none;
  }
  > ul {
    padding: 0 2rem;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
  li > ul {
    padding-left: 1rem;
    a {
      font-size: 1.4rem;
    }
  }
`
const MainHeaderText = styled.p`
  font-family: "Serif";
  font-weight: bold;
  font-size: 1.5em;
  margin: 0;
`
const ValueHeader = styled.p`
  font-weight: bold;
  font-size: 0.75em;
  margin: 0;
  display: inline;
  font-family: "Serif";
`
const ValueText = styled.p`
  font-size: 0.8em;
  font-style: italic;
  margin: 0;
 display: inline;
`

const ValueContainer = styled.div`
  display: block;
`

const Footer = (props) => {
  const {
    allWpMenu: { nodes },
    footerLogo,
  } = useStaticQuery(graphql`query footerQuery {
  footerLogo: file(relativePath: {eq: "footer-logo.png"}) {
    id
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG, layout: FIXED)
    }
  }
  allWpMenu(filter: {locations: {in: [LEGAL, FOOTER]}}) {
    nodes {
      name
      slug
      menuItems {
        nodes {
          parentId
          label
          id
          path
          title
          target
        }
      }
    }
  }
}
`)
  const legalMenu = nodes.filter(({ slug }) => slug === "legal-menu")[0]
  const footerMenu = nodes.filter(({ slug }) => slug === "main-menu")[0]
  const hierarchicalFooterMenu = flatListToHierarchical(
    footerMenu.menuItems.nodes
  )
  return (
    <StyledFooter>
      <StyledContainer>
        <div>
          {/*
          {footerLogo && (
            <GatsbyImage
              image={footerLogo.childImageSharp.gatsbyImageData}
              alt="watson barn rentals2 logo" />
          )}
          */}
          <blockquote>
            <MainHeaderText>Watson Core Values</MainHeaderText>
            <ValueContainer>
              <ValueHeader>HONESTY: </ValueHeader>
              <ValueText>
                Our goal is to be honest in conversations with customers and team members alike. We will maintain an open, sincere environment, by giving each other the space to speak into our lives for areas of improvement.
              </ValueText>
            </ValueContainer>
            <ValueContainer>
              <ValueHeader>RESPECT: </ValueHeader>
              <ValueText>
                We commit to respecting each other in our unique skills and personalities; inspiring each other to become better individuals within our company and communities. We call everyone to a standard of unequivocal excellence while giving grace through mistakes that may be made.
              </ValueText>
            </ValueContainer>
            <ValueContainer>
              <ValueHeader>DILIGENCE: </ValueHeader>
              <ValueText>
                It is essential that we convey examples of taking responsibility, being reliable, and following through on projects to completion. We value self-motivated individuals that are constantly looking for ways to grow personally and professionally.
              </ValueText>
            </ValueContainer>
            <ValueContainer>
              <ValueHeader>CLARITY: </ValueHeader>
              <ValueText>
                We recognize the benefit and importance of clarity. We strive for clear simplicity in all of our interactions and communications.
              </ValueText>
            </ValueContainer>
            <ValueContainer>
              <ValueHeader>SERVICE: </ValueHeader>
              <ValueText>
                We commit to treating our employees exceptionally well to show that they matter. Investing in our employees not only improves them as a person, but also enables them to perform better for the company and the customers we serve.
              </ValueText>
            </ValueContainer>
          </blockquote>
        </div>
        <FooterNav>
          <ul>
            {hierarchicalFooterMenu.map(
              ({ id, label, path, children, parentId, ...rest }) => {
                if (children.length > 0)
                  return (
                    <li key={id}>
                      <UniversalLink
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        to={path}
                        {...rest}
                      >
                        {label}
                      </UniversalLink>
                      <ul>
                        {children.map(
                          ({ id, parentId, label, path, ...rest }) => (
                            <li key={id}>
                              <UniversalLink
                                whileHover={{ scale: 1.09 }}
                                whileTap={{ scale: 0.95 }}
                                to={path}
                                {...rest}
                              >
                                {label}
                              </UniversalLink>
                            </li>
                          )
                        )}
                      </ul>
                    </li>
                  )

                  return null
              }
            )}
          </ul>
        </FooterNav>
        <div>
          <h3>
            <FaMailBulk />
            Get in Touch
          </h3>
          <Button
            backgroundColor="danger"
            uri="/learn-more/"
            text="Contact Us"
            icon="envelope"
          />
          <ul>
            <li>
              <FaPhoneSquare />
              <Phone phone="9316352244" />
            </li>
            <li>
              <FaRegMap />
              <UniversalLink
                target="_BLANK"
                rel="noopener noreferrer"
                to="https://goo.gl/maps/UrQeJfnimHx"
              >
                1367 Old Manchester Rd. <br /> Morrison, TN 37357
              </UniversalLink>
            </li>
            <li>
              <a
                className="frhzbus"
                target="_BLANK"
                href="https://www.bbb.org/nashville/business-reviews/tool-and-utility-sheds/watson-barn-rentals-in-morrison-tn-37062222#bbbseal"
                title="Watson Barn Rentals, LLC, Tool & Utility Sheds, Morrison, TN"
                rel="noopener noreferrer"
                style={{ height: `42px`, paddingLeft: `1rem` }}
              >
                <img
                  src="https://seal-nashville.bbb.org/logo/frhzbus/watson-barn-rentals-37062222.png"
                  alt="Watson Barn Rentals, LLC, Tool & Utility Sheds, Morrison, TN"
                  style={{ marginBottom: 0 }}
                />
              </a>
            </li>
            <li>
              <span>Follow Us:</span>
              <a
                href="https://facebook.com/WatsonBarnRentals"
                target="_BLANK"
                rel="noopener noreferrer"
                aria-label="Link to our facebook page"
              >
                <FaFacebookF />
              </a>
            </li>
          </ul>
        </div>
        <LegalNavContainer>
          <nav>
            {legalMenu.menuItems.nodes.map(
              ({ label, path, id, parentId, ...rest }) => (
                <UniversalLink
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  {...rest}
                  to={path}
                  key={id}
                >
                  {label}
                </UniversalLink>
              )
            )}
          </nav>
        </LegalNavContainer>
      </StyledContainer>
    </StyledFooter>
  );
}

Footer.propTypes = {}

export default Footer
