import React from "react"
import Footer from "./src/components/Footer"
import Header from "./src/components/header"
import Root from "./src/components/Root"
import { MenuStateProvider } from "./src/contexts/menuStateContext"

// * Self-hosted Font imports
require("typeface-cabin")
require("typeface-merriweather")
require("typeface-roboto")

const addScript = (d, s, id, source, refresh = false) => {
  let js
  if (d.getElementById(id)) {
    if (!refresh) return
    d.getElementById(id).remove()
  }
  js = d.createElement(s)
  js.id = id
  js.src = source
  d.getElementsByTagName("head")[0].appendChild(js)
}

export const onRouteUpdate = ({ location }) => {
  const { pathname } = location
  if (pathname === "/" || pathname === "/reviews/") {
    addScript(
      document,
      "script",
      "EmbedSocialReviewsScript",
      "https://embedsocial.com/embedscript/ri.js",
      true
    )
  }
  addScript(
    document,
    "script",
    "CallRailNumberScript",
    "https://cdn.callrail.com/companies/646012712/27ba5e9b1502dc8e512f/12/swap.js"
  )

  // create load event so that script tags work
  var evt = document.createEvent("Event")
  evt.initEvent("load", false, false)
  window.dispatchEvent(evt)
}

export const wrapRootElement = ({ element }) => {
  return (
    <Root>
      <MenuStateProvider>
        <Header />
        {element}
        <Footer />
      </MenuStateProvider>
    </Root>
  )
}
