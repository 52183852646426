exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-product-discontinued-js": () => import("./../../../src/pages/product-discontinued.js" /* webpackChunkName: "component---src-pages-product-discontinued-js" */),
  "component---src-pages-store-closed-js": () => import("./../../../src/pages/store-closed.js" /* webpackChunkName: "component---src-pages-store-closed-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-inventory-js": () => import("./../../../src/templates/inventory.js" /* webpackChunkName: "component---src-templates-inventory-js" */),
  "component---src-templates-single-inventory-item-js": () => import("./../../../src/templates/single/InventoryItem.js" /* webpackChunkName: "component---src-templates-single-inventory-item-js" */),
  "component---src-templates-single-location-js": () => import("./../../../src/templates/single/Location.js" /* webpackChunkName: "component---src-templates-single-location-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single/Page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/Post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-single-product-js": () => import("./../../../src/templates/single/Product.js" /* webpackChunkName: "component---src-templates-single-product-js" */)
}

