import React, { useState } from "react"
import { ThemeProvider } from "styled-components"
const MenuStateContext = React.createContext([{}, () => {}])

const MenuStateProvider = (props) => {
  const [state, setState] = useState({
    isMenuOpen: false,
    browserWidth: 0,
  })
  // const darkMode = useDarkMode()
  return (
    <ThemeProvider theme={{ mode:  "light" }}>
      <MenuStateContext.Provider value={[state, setState]}>
        {props.children}
      </MenuStateContext.Provider>
    </ThemeProvider>
  )
}

export { MenuStateContext, MenuStateProvider }
