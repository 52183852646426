import PropTypes from "prop-types"
import React from "react"
import { FaChevronDown } from "react-icons/fa"
import styled, { css } from "styled-components"
import theme from "styled-theming"
import { colors } from "../../styles/theme"
import { normalizePath } from "../../utils/get-url-path"
import UniversalLink from "../UniversalLink"
const color = theme("mode", {
  light: colors.black,
  dark: colors.primary.light,
})

const backgroundColor = theme("mode", {
  light: colors.offWhite,
  dark: colors.grayscale.light1,
})

const themedLinkColors = theme("mode", {
  light: css`
    color: ${colors.black};
    &:hover {
      color: ${colors.white};
    }
    &:focus {
      color: ${colors.black};
    }
  `,
  dark: css`
    color: ${colors.white};
    &:hover {
      color: ${colors.black};
    }
    &:focus {
      color: ${colors.white};
    }
  `,
})
const DropdownArrow = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: max-content;
  font-size: 1.4rem;
  margin-right: 0.5rem;
  color: ${color};
  cursor: pointer;
  ${(p) => p.isOpen && `transform:rotate(180deg)`};
  transition: transform 200ms ease;
  border-radius: 50%;
  padding: 4px;
  &:focus,
  &:hover {
    color: ${color};
  }
  &:focus {
    outline: 1px dashed ${color};
  }
  &:active {
    color: ${colors.primary.default};
  }
`
const StyledDropdown = styled.ul`
  position: absolute;
  top: 4rem;
  right: 0;
  display: block;
  padding-right: 2rem;
  width: max-content;
  list-style: none;
  text-align: right;
  line-height: calc(var(--line-height) * 1.5);
  z-index: var(--z-index-floating);
  background: ${backgroundColor};
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 1rem 2rem ${colors.grayscale.default};
  & > li {
    &:first-child {
      padding-top: 1rem;
    }
    &:last-child {
      padding-bottom: 1rem;
    }
    padding: 0.5rem 2rem;
    &:hover,
    &:focus {
      color: inherit;
      color: ${color};
      background-color: ${colors.accentDark.default};
    }
  }
  & a {
    /* padding-left: 2rem; */
    width: 100%;
    text-decoration: none;
    font-style: normal;
    ${themedLinkColors};
    letter-spacing: 0.5px;
    display: inline-block;
    transition: all 200ms ease;
    &:hover,
    &:focus {
      transform: translateX(-1rem);
    }
  }
  & a.dropdown-item--active {
    color: ${colors.accentDark.default};
    pointer-events: none;
  }
`
const DropdownMenu = ({
  id,
  nodes,
  isDropdownOpen,
  toggleDropdown,
  dropdownIndex,
  closeMenu,
}) => {
  return (
    <>
      <DropdownArrow
        aria-pressed="false"
        aria-label="Toggle dropdown menu"
        onClick={() => toggleDropdown(id)}
        isOpen={dropdownIndex === id && isDropdownOpen}
      >
        <FaChevronDown />
      </DropdownArrow>
      {isDropdownOpen && dropdownIndex === id && (
        <StyledDropdown>
          {nodes.length > 0 &&
            nodes.map((item) => {
              const { id, label, url, cssClasses, parentId, ...rest } = item

              return (
                <li key={id}>
                  <UniversalLink
                    partiallyActive={true}
                    activeClassName="dropdown-item--active"
                    to={normalizePath(url)}
                    {...rest}
                    onClick={() => {
                      toggleDropdown()
                      closeMenu()
                    }}
                  >
                    {label}
                  </UniversalLink>
                </li>
              )
            })}
        </StyledDropdown>
      )}
    </>
  )
}

DropdownMenu.propTypes = {
  id: PropTypes.string.isRequired,
  nodes: PropTypes.any,
}

export default DropdownMenu
