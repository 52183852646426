import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import styled from "styled-components"
import useMenuState from "../../hooks/useMenuState"
import { device } from "../../styles/theme"
import { flatListToHierarchical } from "../../utils/get-url-path"
import MenuItem from "./MenuItem"
import SidedrawerNavigation from "./SidedrawerNavigation"
const StyledPrimaryNav = styled.nav`
  flex-basis: 100%;
  margin-top: 2rem;
  @media ${device.xl} {
    margin-top: 0;
  }
  > ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    > li {
      display: flex;
      align-items: center;
      position: relative;
      color: var(--primary-color-lighter);
      > a {
        display: flex;
        align-items: center;
      }
      > a,
      > a svg {
        margin-right: 1rem;
      }
    }
  }
`

export default function PrimaryMenu()  {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "main-menu" }) {
        name
        menuItems {
          nodes {
            id
            label
            url
            parentId
            cssClasses
          }
        }
      }
    }
  `)
  const { browserWidth, isMenuOpen, closeMenu } = useMenuState()

  useEffect(() => {
    if (browserWidth >= 750) {
      closeMenu()
    }
  }, [browserWidth])
  const hierarchicalMenu = flatListToHierarchical(wpMenu.menuItems.nodes)
  if (browserWidth >= 750 && !!wpMenu) {
    return (
      <StyledPrimaryNav length={hierarchicalMenu.length}>
        <ul>
          {hierarchicalMenu.map((menuItem, i) => {
            return (
              <li key={menuItem.id}>
                <MenuItem menuItem={menuItem} />
              </li>
            )
          })}
        </ul>
      </StyledPrimaryNav>
    )
  } else if (browserWidth < 750 && isMenuOpen) {
    return <SidedrawerNavigation items={hierarchicalMenu} />
  }
  return null
}
