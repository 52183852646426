const colors = {
  mainBrand: `hsl(199,100%,29%)`,
  black: `hsl(195, 6%, 14%)`,
  offWhite: `hsl(240,1%,72%)`, //192, 15%, 94%
  white: `hsl(0,0%,100%)`,
  red: `hsl(0, 100%, 50%)`,
  blue: `hsl(199,100%,29%)`,
  siteBackground: {
    light: `hsl(240, 0%, 92%)`,
    dark: `hsl(0 , 0%, 7%)`,
  },
  primary: {
    hue: 199, //watson blue of buttons
    saturation: 100,
    lightness: 29,
    get default() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness}%)`
    },
    get light() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 10}%)`
    },
    get lighter() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 20}%)`
    },
    get lightest() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 30}%)`
    },
    get dark() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness - 10}%)`
    },
  },
  secondary: {
    hue: 240, //background that is pink right now
    saturation: 1,
    lightness: 85,
    get default() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness}%)`
    },
    get light() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 10}%)`
    },
    get dark() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness - 10}%)`
    },
  },
  accentLight: {
    hue: 199, //buttons stuff that is green right now
    saturation: 100,
    lightness: 29,
    get default() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness}%)`
    },
    get light() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 10}%)`
    },
    get dark() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness - 10}%)`
    },
  },
  accentDark: {
    hue: 240, //footer color
    saturation: 1,
    lightness: 75,
    get default() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness}%)`
    },
    get light() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 10}%)`
    },
    get dark() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness - 10}%)`
    },
  },
  grayscale: {
    hue: 0,
    saturation: 0,
    lightness: 7,
    get default() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness}%)`
    },
    get light1() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 10}%)`
    },
    get light2() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 30}%)`
    },
    get light3() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 73}%)`
    },
    get light4() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 80}%)`
    },
    get light5() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 84}%)`
    },
  },
  danger: {
    hue: 0, //red buttons
    saturation: 50,
    lightness: 43,
    get default() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness}%)`
    },
    get light() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness + 10}%)`
    },
    get dark() {
      return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness - 10}%)`
    },
  },
}

const typography = {
  fontSize: `1.8rem`,
  fontWeights: [100, 300, 500, 700],
  fontStack: {
    title: `"Roboto", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Helvetica", "Arial", "sans-serif"`,
    heading: `"Merriweather", "Lucida Bright", "Lucidabright","Lucida Serif", "Lucida", "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif`,
    body: `'Cabin','Helvetica Neue','Arial',-apple-system,'BlinkMacSystemFont','Segoe UI',sans-serif`,
  },
  lineHeight: 1.5,
}

const zIndex = {
  initial: 0,
  elevated: 100,
  floating: 200,
}

const sizes = {
  xs: 25,
  sm: 34.375,
  md: 46.875,
  lg: 62.5,
  xl: 75,
  xxl: 100,
}

const device = Object.keys(sizes).reduce((acc, curr) => {
  acc[curr] = `(min-width: ${sizes[curr]}rem)`
  return acc
}, {})

export { colors, typography, zIndex, device }
