// @todo once the source plugin is updated to the latest WPGQL version, we wont need this helper anymore
export const normalizePath = (path) => {
  if (!path?.endsWith(`/`) && !path?.startsWith(`http`)) {
    path = `${path}/`
  }

  if (!path?.startsWith(`/`) && !path?.startsWith(`http`)) {
    path = `/${path}`
  }

  return path
}

export const flatListToHierarchical = (
  data = [],
  { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = []
  const childrenOf = {}
  data.forEach((item) => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem

    childrenOf[id] = childrenOf[id] || []
    newItem[childrenKey] = childrenOf[id]
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem)
  })
  return tree
}
