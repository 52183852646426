import { parsePhoneNumberFromString } from "libphonenumber-js/min"
import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"

const A = styled.a`
  text-color: rgb(0,101,148);
  text-decoration: none;
  font-weight: bold;
  font-size: 3rem;
`

const HeaderPhone = ({ phone }) => {
  const formattedPhone = parsePhoneNumberFromString(phone, "US").format(
    "NATIONAL"
  )

  return <A href={`tel:+1 ${formattedPhone}`}>{formattedPhone}</A>
}

HeaderPhone.propTypes = {
  phone: PropTypes.string.isRequired,
}

export default HeaderPhone
