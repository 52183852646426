import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { normalizePath } from "../utils/get-url-path"
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const UniversalLink = React.forwardRef(
  (
    {
      children,
      to,
      className,
      activeClassName,
      partiallyActive,
      isDark,
      whileHover,
      initial,
      ...other
    },
    ref
  ) => {
    /**
     * Tailor the following test to your environment.
     * This example assumes that any internal link (intended for Gatsby)
     * will start with exactly one slash, and that anything else is external
     *
     */

    const internal = /^\/(?!\/)/.test(to)
    //  Use Gatsby Link for internal links and <a> for others
    if (internal) {
      const path = normalizePath(to)
      return (
        <GatsbyLink
          ref={ref}
          to={path}
          className={className}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
        >
          {children}
        </GatsbyLink>
      )
    }
    return (
      <a
        ref={ref}
        href={to}
        className={className}
        {...other}
        target="_BLANK"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }
)

export default UniversalLink
