/**
 * Parses a link or object and returns Gatsby-field URI.
 *
 * @param {string||object} linkItem a single menu item or a link
 * @param blogURI
 */
import { graphql, useStaticQuery } from "gatsby"
import { normalizePath } from "./get-url-path"
export const CreateLocalLink = (linkItem, blogURI = "blog/") => {
  const data = useStaticQuery(graphql`
    query GETWPURL {
      wp {
        generalSettings {
          url
        }
      }
    }
  `)
  const {
    wp: {
      generalSettings: { url: wordPressUrl },
    },
  } = data
  let newUri
  /**
   * if the link is a menu item, then we will pull out the url and the connectedObject
   */
  if (typeof linkItem === "object") {
    const { url, connectedObject } = linkItem
    if (url === "#") {
      return null
    }
    /**
     * Always want to pull off our API URL
     */

    newUri = url.replace(wordPressUrl, "")

    /**
     * If it's a blog link, respect the users blogURI setting.
     */

    if (connectedObject && connectedObject.__typename === "WpPost") {
      newUri = blogURI + newUri
    }

    return normalizePath(newUri)
  } else if (typeof linkItem === "string") {
    newUri = linkItem.replace(wordPressUrl, "")
    return normalizePath(newUri)
  }
}
