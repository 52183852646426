import { globalHistory, Location } from "@reach/router"
import React from "react"
import { QueryParamProvider } from "use-query-params"

export default function Root({ children }) {
  return (
    <Location>
      {({ location }) => (
        <QueryParamProvider location={location} reachHistory={globalHistory}>
          {children}
        </QueryParamProvider>
      )}
    </Location>
  )
}
