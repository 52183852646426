import React from "react"
import styled, { css } from "styled-components"
import theme from "styled-theming"
import useMenuState from "../../hooks/useMenuState"
import { colors, device } from "../../styles/theme"
import { availableIcons } from "../../utils/availableIcons"
import { normalizePath } from "../../utils/get-url-path"
import UniversalLink from "../UniversalLink"
import DropdownMenu from "./DropdownMenu"
const themedMenuItem = theme("mode", {
  light: css`
    color: ${colors.black};
    &:hover,
    &:focus {
      color: ${colors.accentDark.light};
    }
  `,
  dark: css`
    color: ${colors.primary.lighter};
    &:hover,
    &:focus {
      color: ${colors.primary.default};
    }
  `,
})
const StyledMenuItem = styled(UniversalLink)`
  ${themedMenuItem};
  font-size: calc(var(--default-font-size) / 1.25);
  text-decoration: none;
  font-style: normal;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
  &.top-navigation {
    color: ${colors.accentDark.default};
    font-weight: bold;
  }
  @media ${device.xxl} {
    font-size: var(--default-font-size);
  }
`

const MenuItem = ({ menuItem }) => {
  const { id, label, children, cssClasses, ...rest } = menuItem

  const {
    closeMenu,
    toggleDropdown,
    isDropdownOpen,
    dropdownIndex,
  } = useMenuState()

  const path = menuItem.url

  const handleChange = () => {
    closeMenu()
    toggleDropdown()
  }

  const handleCustomLink = (e) => {
    e.preventDefault()
    toggleDropdown(id)
  }
  return (
    <>
      <StyledMenuItem
        partiallyActive={menuItem.url !== "/" ? true : false}
        activeClassName="top-navigation"
        to={normalizePath(path)}
        title={rest.title}
        target={rest.target}
        onClick={
          label && label !== "Inventory" && label !== "Join Us & More" ? handleChange : handleCustomLink
        }
      >
        {availableIcons[cssClasses[0]]}
        {menuItem.label}
      </StyledMenuItem>
      {children.length > 0 && (
        <DropdownMenu
          isDropdownOpen={isDropdownOpen}
          toggleDropdown={toggleDropdown}
          dropdownIndex={dropdownIndex}
          closeMenu={closeMenu}
          nodes={children}
          id={menuItem.id}
        />
      )}
    </>
  )
}

export default MenuItem
