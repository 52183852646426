import PropTypes from "prop-types"
import React from "react"
import styled, { css, keyframes } from "styled-components"
import theme from "styled-theming"
import { colors } from "../styles/theme"
import { availableIcons } from "../utils/availableIcons"
import { CreateLocalLink } from "../utils/createLocalLink"
import UniversalLink from "./UniversalLink"

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(100%);
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const themedButtonVariants = theme.variants("mode", "variant", {
  accentDark: {
    light: css`
      && {
        background-color: ${colors.accentDark.default};
        color: ${colors.black};
        &:hover,
        &:focus {
          background-color: ${colors.accentDark.light};
        }
        &:active {
          background-color: ${colors.accentDark.default};
        }
        &:focus {
          outline-color: ${colors.accentDark.default};
        }
      }
    `,
    dark: css`
      && {
        background-color: ${colors.accentDark.default};
        color: ${colors.black};
        &:hover,
        &:focus {
          background-color: ${colors.accentDark.light};
        }
        &:active {
          background-color: ${colors.accentDark.default};
        }
      }
    `,
  },
  danger: {
    light: css`
      && {
        background-color: ${colors.danger.default};
        color: ${colors.white};
        position: relative;
        &:hover,
        &:focus {
          background-color: ${colors.danger.light};
        }
        &:active {
          background-color: ${colors.danger.dark};
        }
        &:focus {
          outline-color: ${colors.danger.default};
        }
      }
    `,
    dark: css`
      && {
        background-color: ${colors.danger.dark};
        color: ${colors.white};
        &:hover,
        &:focus {
          background-color: ${colors.danger.default};
        }
        &:active {
          background-color: ${colors.danger.dark};
        }
      }
    `,
  },
  primaryColor: {
    light: css`
      && {
        background-color: ${colors.primary.default};
        color: ${colors.white};
        &:hover,
        &:focus {
          background-color: ${colors.primary.light};
        }
        &:active {
          background-color: ${colors.primary.default};
        }
        &:focus {
          outline-color: ${colors.primary.default};
        }
      }
    `,
    dark: css`
      && {
        background-color: ${colors.primary.default};
        color: ${colors.white};
        &:hover,
        &:focus {
          background-color: ${colors.primary.light};
        }
        &:active {
          background-color: ${colors.primary.default};
        }
      }
    `,
  },
  secondaryColor: {
    light: css`
      && {
        background-color: ${colors.secondary.default};
        color: ${colors.black};
        &:hover,
        &:focus {
          background-color: ${colors.secondary.light};
        }
        &:active {
          background-color: ${colors.secondary.dark};
        }
        &:focus {
          outline-color: ${colors.secondary.default};
        }
      }
    `,
    dark: css`
      && {
        background-color: ${colors.secondary.dark};
        color: ${colors.black};
        &:hover,
        &:focus {
          background-color: ${colors.secondary.default};
        }
        &:active {
          background-color: ${colors.secondary.dark};
        }
      }
    `,
  },
  accentLight: {
    light: css`
      && {
        background-color: ${colors.accentLight.default};
        color: white;
        &:hover,
        &:focus {
          background-color: ${colors.accentLight.light};
        }
        &:active {
          background-color: ${colors.accentLight.dark};
        }
        &:focus {
          outline-color: ${colors.accentLight.default};
        }
      }
    `,
    dark: css`
      && {
        background-color: ${colors.accentLight.dark};
        color: ${colors.black};
        &:hover,
        &:focus {
          background-color: ${colors.accentLight.default};
          outline-color: ${colors.accentLight.default};
        }
        &:active {
          background-color: ${colors.accentLight.dark};
        }
      }
    `,
  },
})

const StyledButton = styled(UniversalLink)`
  ${themedButtonVariants};
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: max-content;
  transition: all 150ms ease-in;
  text-decoration: none;
  box-shadow: 0 0.25rem 1rem ${colors.grayscale.default};
  margin: 2rem 0 0;
  svg {
    margin-right: 1rem;
  }
  &:hover,
  &:focus {
    transform: translateY(-0.5rem);
    box-shadow: 0 1rem 2rem ${colors.black};
    svg {
      animation: ${slideDown} 150ms linear forwards;
    }
  }

  &:focus {
    outline-offset: 3px;
    outline-width: 2px;
  }

  &:active {
    transform: translateY(-0.25rem);
    box-shadow: 0 0.25rem 1rem ${colors.grayscale.default};
  }
`
const Button = ({ backgroundColor, icon, text, uri, isConfiguratorLink }) => {
  const to = isConfiguratorLink ? uri : CreateLocalLink(uri)
  return (
    <StyledButton
      variant={backgroundColor !== null ? backgroundColor : "primaryColor"}
      to={to}
    >
      {!!icon && availableIcons[icon]}
      {text}
    </StyledButton>
  )
}

Button.propTypes = {
  backgroundColor: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  uri: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

StyledButton.propTypes = {
  variant: PropTypes.oneOf([
    "default",
    "primaryColor",
    "secondaryColor",
    "accentLight",
    "accentDark",
    "danger",
  ]),
}
StyledButton.defaultProps = {
  variant: "primaryColor",
}

export default Button
