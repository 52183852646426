import { parsePhoneNumberFromString } from "libphonenumber-js/min"
import PropTypes from "prop-types"
import React from "react"

const Phone = ({ phone }) => {
  const formattedPhone = parsePhoneNumberFromString(phone, "US").format(
    "NATIONAL"
  )

  return <a href={`tel:+1 ${formattedPhone}`}>{formattedPhone}</a>
}

Phone.propTypes = {
  phone: PropTypes.string.isRequired,
}

export default Phone
