import React from "react"
import styled, { css } from "styled-components"
import theme from "styled-theming"
import useMenuState from "../../hooks/useMenuState"
import { colors, device } from "../../styles/theme"

const toggleStyles = theme("mode", {
  light: colors.siteBackground.light,
  dark: colors.siteBackground.dark,
})

const MenuToggle = styled.button`
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  border: none;
  position: relative;
  z-index: 1000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary.default};
  @media ${device.md} {
    top: 2rem;
  }
  span {
    position: relative;
    &,
    &::before,
    &::after {
      background-color: ${toggleStyles};
      display: inline-block;
      width: 3rem;
      height: 2px;
      transition: all 0.2s ease;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
    }
    &::before {
      top: -0.8rem;
    }
    &::after {
      top: 0.8rem;
    }
  }
  ${(p) =>
    p.isMenuOpen &&
    css`
      span {
        background-color: transparent;
        &::before {
          transform: rotate(45deg);
          top: 0;
        }
        &::after {
          transform: rotate(-45deg);
          top: 0;
        }
      }
    `}
`

const Toggle = (props) => {
  const { browserWidth, isMenuOpen, toggleMenu } = useMenuState()

  if (browserWidth < 750) {
    return (
      <MenuToggle
        isMenuOpen={isMenuOpen}
        onClick={() => toggleMenu()}
        aria-label="Toggle Menu"
      >
        <span>&nbsp;</span>
      </MenuToggle>
    )
  } else {
    return null
  }
}

Toggle.propTypes = {}

export default Toggle
